import React from "react";

import Layout from "../../../components/layout";
import { Helmet } from 'react-helmet';
import data from '../../../data/limitations';
import Doc from "../../resources/NA_CAB-Pressure-Cab-Protection-Levels.pdf"

export default function () {

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Limitations | Clean Air Filter</title>
        </Helmet>
        <article>
          <section>
            <h1>
              <span className="tail">
                <span className="head">{data.content.section1.h1.head}</span>{" "}
                {data.content.section1.h1.tail}
              </span>
            </h1>
            <article className="w-60">
              <p>{data.content.section1.p1}</p>
              <p>{data.content.section1.p2}</p>
              <p>{data.content.section1.p3}</p>
              <a
                className="download-link"
                target="_blank"
                rel="noreferrer"
                href={Doc}
              >
                {data.content.section1.a1}
              </a>
            </article>
          </section>
        </article>
      </Layout>
    )
}
